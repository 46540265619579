import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Joseph Fraser - Senior Front End Engineer" />
      <h2 css={styles.heading}>Senior Front End Developer</h2>
      <h1 css={styles.heading}>Joseph Fraser</h1>
      <p>The best code is no code.</p>

      <div css={styles.contactlist}>
        <p css={styles.contactitem}>
          Email me on{" "}
          <a href="mailto:hello@josephfraser.dev">hello@josephfraser.dev</a>
        </p>
        <p css={styles.contactitem}>
          <a href="https://github.com/josephfraser">Github</a>
        </p>
        <p css={styles.contactitem}>
          <a href="https://www.linkedin.com/in/josephfraserdev/">Linkedin</a>
        </p>
      </div>
    </Layout>
  );
}

const styles = {
  heading: {
    marginBottom: 0,
    marginTop: 0,
  },

  contactlist: {
    marginTop: "60px",
  },

  contactitem: {
    marginBottom: 0,
    marginTop: 0,
  },
};
